/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { tokens } from './tokens';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: tokens.palette_primary,
    },
    secondary: {
      main: tokens.palette_secondary,
    },
    error: {
      main: tokens.token_red,
    },
    success: {
      main: tokens.token_green,
    },
    warning: {
      main: tokens.token_yellow,
    },
    info: {
      main: tokens.token_blue,
    },
    background: {
      default: tokens.token_surface,
      paper: tokens.token_surface,
    },
    divider: 'rgb(236,237,239)',
  },
  props: {
    MuiAppBar: {
      color: 'default',
      elevation: 0,
    },
    MuiButtonBase: {
      disableRipple: true,
      textTransform: 'capitalize',
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        textTransform: 'capitalize',
        variant: 'small',
        fontFamily: 'Roboto,arial,sans-serif',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',

        fontFamily: 'Roboto,arial,sans-serif',
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        transition:
          'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  shape: {
    borderRadius: 4,
    color: tokens.token_menu_border_grey,
  },
  typography: {
    ty1: {
      fontFamily: 'Roboto, Arial,  sans-serif',
      fontSize: '0.7500em',
      fontWeight: 400,
      color: tokens.token_typography_regular,
    },
    ty2: {
      fontFamily: 'Roboto, Arial,  sans-serif',
      fontSize: '0.8750em',
      fontWeight: 400,
      color: tokens.token_typography_regular,
    },
    ty3: {
      fontFamily: 'Roboto, Arial,  sans-serif',
      fontSize: '1.00em',
      fontWeight: 500,
      color: tokens.token_typography_subhead,
    },
    ty4: {
      fontFamily: 'Roboto, Arial,  sans-serif',
      fontSize: '1.2500em',
      fontWeight: 500,
      color: tokens.token_typography_headline,
    },
  },
});

export default theme;
