/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars

const tokens = {
  /* Colors */

  /* primary color ____________________________________________________________________________ */

  palette_primary: '#0A2B3B' /* brand color */,
  token_primary_background: '#C7EDFF',
  palette_primary_light: '005B69' /* original "#3B5562" */,
  palette_primary_dark: '#071E29',

  /* secondary color_accent color ______________________________________________________________ */

  palette_secondary: '#FF7E24',
  token_secondary_background: '#FFE1CC',
  palette_secondary_light: '#FFAB71',
  palette_secondary_dark: '#FF5619',

  /* node tokens ______________________________________________________________________________ */
  token_grey_base_one: '#BFBFBF',
  token_grey_border_one: '#C0C0C0',

  token_grey_base_two: '#8E8E8E',
  token_grey_border_two: '#707070',

  token_grey_base_three: '#5C5C5C',
  token_grey_border_three: '#282828',

  token_grey_base_four: '#2E2E2E',
  token_grey_base_five: '#1A1A1A',

  /* grayscale ________________________________________________________________________________ */

  token_grey_10: '#f5f7f8' /* footer */,
  token_grey_50: '#F8F9FA' /* search button color */,
  token_grey_100: '#F1F3F4' /** action icon button background */,
  token_grey_200: '#EEEEEE',
  token_grey_300: '#DADCE0',
  token_grey_400: '#B2BAC2',
  token_grey_500: '#9aa0a6',
  token_grey_600: '#80868b',
  token_grey_700: '#5f6368',
  token_grey_800: '#3c4043',
  token_grey_900: '#202124',
  token_table_background: '#e8eaed',
  token_table_bottom_border: '#bdc1c6',
  token_table_head_background: '#F8F9FA',
  token_node_secondary_fill: '#E0E2E3',
  token_node_secondary_stroke: '#acb0b5',
  token_node_tertiary_fill: '#D1D3D4',
  token_node_tertiary_stroke: '#9b9fa5',
  token_card_background: '#f7f7f8',

  /* icon colors _______________________________________________________________________________ */
  token_card_description_grey: '#5f6368',
  token_text_grey_on_white: '#5F6368' /* icon color */,
  token_text_grey_on_grey: '#70757A',
  token_menu_border_grey: '#ebebeb' /* desktop menu underline */,
  token_search_icon_grey: '#9AA0A6',
  token_search_border_grey: '#DFE1E5',
  token_search_field_grey: '#dfe1e5',
  token_details_button_grey: '#d8d7dc',
  token_border_top: '#0000001E',
  token_transparent: '#00FFFFFF.',
  token_link_grey: '#757575',
  token_caution: '#fef8ea',
  token_caution_font: '#644a14',
  token_caution_icon: '#fabb33',
  token_warning: '#fff3e0',
  token_warning_dark: '#f9ab00',
  token_warning_font: '#644a14',
  token_warning_icon: '#ff9800',
  token_error: '#fbeeed',
  token_error_font: '#592320',
  token_error_icon: '#e05950',
  token_info: '#ecf3fd',
  token_info_font: '#1c395e',
  token_info_icon: '#478fec',
  token_typography_headline: '#202124',
  token_typography_subhead: '#202124',
  token_typography_body: '#4D5156',
  token_typography_regular: '#70757A',
  token_typography_emphasized:
    '#202124' /* button text color on gray background */,
  token_typography_regular_emphasized: '#3c4043' /* search button text */,
  token_typography_results_link: '#1a0dab' /* Link blue color */,
  token_typography_report_h1: '#1f1f1f',

  /* brand icon colors_________________________________________________________________________ */
  token_google_blue: '#4C8BF5',
  token_apple_grey: '#A2AAAD',

  token_coingecko: '#4a4a4a',
  token_warning2: '#ffb74d',
  token_secondary_icon: '#5F6368',
  token_surface: '#fff',
  token_on_surface: '#3c4043',
  token_on_surface_state_layer: '#3c4043',
  token_on_surface_state_content: '#202124',
  token_scrim_opacity: 0.6,
  token_scrim: '202124' /* opacity 0.6 */,
  token_hairline: '#dadce0',
  token_canvas: '#f1f3f4',
  token_footer: 'f8f9fa',
  token_primary: '#1a73e8',
  token_primary_variant: '#4285f4',
  token_primary_state_content: '#174ea6',
  token_primary_state_layer: '#1a73e8',
  token_on_primary: '#fff',
  token_secondary: '#e8f0fe',
  token_secondary_intelligence_variant: 'E8F0FE' /* opacity 0.5 */,
  token_on_secondary: '#1967d2',
  token_on_secondary_state_layer: '#1967d2',
  token_on_secondary_state_content: '#174ea6',
  token_tertiary: '#f1f3f4',
  token_on_tertiary: '#202124',
  token_on_tertiary_state_layer: '#202124',
  token_disabled_container_opacity: 0.12,
  token_disabled_content_opacity: 0.38,
  token_disabled_container: '#3c4043' /* opacity 0.1 */,
  token_disabled_content: '#3c4043' /* opacity 0.3 */,
  token_loading: '#e8eaed',
  token_white: '#fff',
  token_black: '#202124',
  token_blue_50: '#e8f0fe',
  token_blue_100: '#d2e3fc',
  token_blue_200: '#bae3ff',
  token_blue_500: '#4285f4',
  token_blue_600: '#1a73e8',
  token_blue_700: '#1967d2',
  token_blue: '#1a73e8',
  token_twitter_blue: '#1DA1F2',
  token_telegram_blue: '#229ED9',
  token_green_50: '#e6f4ea',
  token_green_100: '#ceead6',
  token_green_400: '#66bb6a',
  token_green_600: '#1e8e3e',
  token_green_700: '#188038',
  token_green: '#1e8e3e',
  token_green_border: '#34a853',
  token_green_typography: '#137333',
  token_yellow_50: '#fef7e0',
  token_yellow_100: '#feefc3',
  token_yellow_500: '#fbbc04',
  token_yellow_600: '#f9ab00',
  token_yellow: '#f9ab00',
  token_reddit_orange: '#FF4500',
  token_red_50: '#fce8e6',
  token_red_100: '#fad2cf',
  token_red_400: '#ef5350',
  token_red_600: '#d93025',
  token_red_700: '#c5221f',
  token_red: '#d93025',
  token_red_typography: '#A50E0E',

  /* Type Settings */
};

export { tokens };
