/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import '../styles/globals.css';
import { SessionProvider } from 'next-auth/react';
import { Analytics } from '@vercel/analytics/react';
import { DefaultSeo } from 'next-seo';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { LicenseInfo } from '@mui/x-license-pro';
import createEmotionCache from '../src/createEmotionCache';
import theme from '../src/theme';
// eslint-disable-next-line import/extensions
import '../styles/Gims.css';

LicenseInfo.setLicenseKey(
  '19c0cbc4018bcbcae439fbd10cce6200Tz03Mzc5NixFPTE3MjUxMTAzMzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <DefaultSeo
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: 'https://www.blockquiry.com/',
          siteName: 'blockquiry',
        }}
        twitter={{
          handle: '@blockquiry',
          site: 'https://twitter.com/blockquiry',
          cardType: 'summary_large_image',
        }}
      />
      <SessionProvider session={pageProps.session}>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {getLayout(<Component {...pageProps} />)}
            <Analytics />
          </ThemeProvider>
        </CacheProvider>
      </SessionProvider>
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
